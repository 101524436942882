<template>
  <div class="commodity-detail">
    <app-detail :opt="opt" ref="detail"></app-detail>
  </div>
</template>
<script>
  let priceVali = (rule, value, callback) => {
    var reg = /(^[0-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,4}$)/
    if (!reg.test(value)) {
      callback(
        new Error("请输入数字，且小数点后最多保留4位")
      );
    } else {
      callback();
    }
  };
  let validItemsName = (rule, value, callback) => {
    if(!value) {
      callback(new Error("请输入商品名称"));
    } else {
      //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
      let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
      if (!reg.test(value)) {
          callback(new Error("商品名称格式不对或超过32个字符"));
      } else {
          callback();
      }
    }
  };
  let validCode = (rule, value, callback) => {
    //支持输入大小写字母，数字，限制1~16位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("商品编号格式不对"));
    } else {
      callback();
    }
  };
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "商品详情",
          form: [{
              label: "供应商名称",
              key: "supplierId",
              type: "select-filterable",
              opt: {
                list: [],
                change(e) {
                _this.supplierChange(e)
                }
              },
              rules: [{
                required: true,
                trigger: "change"
              }]
            }, {
              label: "商品品类",
              key: "type",
              type: "select",
              opt: {
                list: []
              },
              rules: [{
                required: true,
                trigger: "change"
              }]
            },
            {
              label: "商品名称",
              key: "name",
              maxlength: 64,
              rules: [{
                required: true,
                trigger: "change",
                validator: validItemsName,
              }]
            },
            {
              label: "商品编号",
              key: "code",
              disabled: true,
            },
            {
              label: "成本价(元)",
              key: "costPrice",
              rules: [{
                required: true,
                trigger: "change",
                validator: priceVali,
              }]
            },
            {
              label: "销售价(元)",
              key: "sellPrice",
              rules: [{
                required: true,
                trigger: "change",
                validator: priceVali,
              }]
            },
            {
              label: "商品详细描述",
              key: "detail",
              type: "textarea",
              maxlength: 500,
              placeholder: " "
            },
            {
              label: "商品图片",
              key: "imgs",
              type: "upload-img-form",
              rules: [{
                required: true
              }],
              opt: {
                alt: "支持上传1-6张图片，支持png、jpg格式 *第一张为主照片，供手机端首页展示",
                url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                urlKey: "picPath",
                limitFileNameLength: 90,
                upload(data) {
                  // console.log(data);
                  return true;
                },
                remove(file, fileList) {
                  // console.log(file, fileList);
                  return true;
                }
              }
            }
          ],
          buttons: [{
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              let dto = {
                type: form.type,
                name: form.name,
                code: form.code,
                sellPrice: form.sellPrice,
                costPrice: form.supplierId == '1' ? form.sellPrice : form.costPrice,
                // needPhone: form.needPhone,
                detail: form.detail,
                state: 1,//默认传1
                supplierId: form.supplierId == '1' ? null : form.supplierId,
              };
              _this.supplierList.forEach(item => {
                if (form.supplierId == item.value) {
                  dto.supplierName = item.label
                }
              })
              if (dto.supplierName == '自营' || !dto.supplierName) {
                dto.isSelfSale = 1
              } else {
                dto.isSelfSale = 0
              }
              // if (form.costPrice) {
              //   var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,4}$)/;
              //   if (!reg.test(form.costPrice)) {
              //     _this.$message({
              //       showClose: true,
              //       message: '输入的成本价只能为正数，且小数点后最多保留4位',
              //       type: "warning"
              //     });
              //     return;
              //   }
              // }
              dto.picList = [];
              form.imgs.forEach(item => {
                dto.picList.push(item.url);
              })
              let url = "mall-service/items/add";
              let message = "添加商品成功";
              if (_this.$route.params.type == "edit") {
                dto.id = form.id;
                dto.auditState = 0
                url = "mall-service/items/update";
                message = "编辑商品成功";
              }
              _this.post(url, dto).then(function () {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          }],
          get(opt) {
            _this.get("mall-service/items/detail/" + opt.id).then(data => {
              if(data.isSelfSale == 1) {
                data.supplierId = "1"
              }
              data.imgs = [];
              data.picList.forEach((item, index) => {
                data.imgs.push({
                  name: `${index}.jpg`,
                  url: item
                });
              })
              data.costPrice = data.costPrice == null ? '' : data.costPrice.toFixed(2).toString();
              data.sellPrice = data.sellPrice == null ? '' : data.sellPrice.toFixed(2).toString();
              opt.cb(data);
            });
          },
        },
        supplierList: []
      };
    },
    activated() {
      console.log("commodity-detail activated!!");
      this.$refs.detail.title = ["添加商品", "编辑商品", "商品详情"][{
        add: 0,
        edit: 1,
        show: 2
      } [this.$route.params.type]];
      this.opt.form.forEach(item => {
        // 判断添加的时候不展示code
        if (item.key == 'code') {
          if (this.$route.params.type == 'add') {
            item.notShow = true
          } else {
            item.notShow = false
          }
        }
        // 判断审核状态不同的可操作的字段
        if (this.$route.params.type == 'edit') {
          if (this.$route.params.auditState == "审核通过") {
            if (item.key == 'costPrice' || item.key == 'sellPrice') {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
          } else {
            if (item.key == 'code') {
              item.disabled = true;
            } else {
              item.disabled = false;
            }
            this.opt.form[7].opt.alt = "支持上传1-6张图片，支持png、jpg格式 *第一张为主照片，供手机端首页展示"
            document.getElementsByClassName('commodity-detail')[0].classList.remove('upload-edit')
          }
        } else if (this.$route.params.type == 'add') {
          item.disabled = false;
          this.opt.form[7].opt.alt = "支持上传1-6张图片，支持png、jpg格式 *第一张为主照片，供手机端首页展示"
          document.getElementsByClassName('commodity-detail')[0].classList.remove('upload-edit')
        } else {
          item.disabled = true;
          this.opt.form[7].opt.alt = ''
          document.getElementsByClassName('commodity-detail')[0].classList.remove('upload-edit')
        }
      })
      this.supplierList = []
      this.getCategory();
      this.getMerchantList();
    },
    methods: {
      getCategory() {
        let dto = {
          pageNum: 1,
          pageSize: 9999,
          isDisable: 1,
          state: 1,//默认传1
        };
        this.post("mall-service/category/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          var array = res.data.list;
          array.forEach(item => {
            item.value = item.id;
            item.label = item.name;
          })
          this.opt.form.forEach(item => {
            if (item.key == "type") {
              item.opt.list = array;
            }
          })

        })
      },
      supplierChange(e) {
        this.opt.form.forEach(item => {
          if (item.key == 'costPrice') {
            if (e == '1') {
              item.notShow = true
              item.rules[0].required = false
              item.isRequired = false
              item.rules[0].validator = ''
            } else {
              item.rules[0].required = true
              item.notShow = false
              item.isRequired = true
              item.rules[0].validator = priceVali
            }
          }
        })
      },
      getMerchantList() {
        cabinetUtil.getMerchantList({roleType: 2}).then(res => {
          let getMerchantList = res && res.length > 0 ? res : [];
          getMerchantList.unshift({
            label: '自营',
            value: '1',
          })
          this.opt.form.forEach(item => {
            if(item.key == 'supplierId') {
              item.opt.list = res
              this.supplierList = item.opt.list
            }
          })
        })
      },
    }
  };
</script>
<style lang="scss">
  .commodity-detail {
    .el-form-item__label {
      flex: none !important;
      width: 220px;
    }
  }
  .upload-edit {
    .el-upload {
      display: none !important; 
    }
    .el-icon-delete {
      display: none !important; 
    }
  }
</style>